import type { AxiosResponse } from 'axios';
export function longPoll<T>(pollRequest: () => Promise<AxiosResponse<T>>, timeout = 20000, interval = 100): Promise<T> {
	const endTime = Number(new Date()) + timeout;

	const checkCondition = async function (resolve, reject) {
		// If the condition is met, we're done!
		const response = await pollRequest();
		if (response.status === 200) {
			resolve(response.data);
		} else if (response.status === 202 && Number(new Date()) < endTime) {
			setTimeout(checkCondition, interval, resolve, reject);
		} else {
			reject(new Error('Exit long polling!'));
		}
	};

	return new Promise(checkCondition);
}

export function downloadDirectFromUrl(url: any, name: string) {
	const a = document.createElement('a');
	a.href = url;
	a.download = name;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

export function getDefaultPaginationState(pageSize: number) {
	return {
		listOfItems: [],
		currentPage: 0,
		totalPages: null,
		totalItems: 0,
		pageSize,
	};
}


export function createSearchParams(query: { [key: string]: any | any[] }) {
	const params = new URLSearchParams();
	for (const searchKey in query) {
		const searchValue = query[searchKey];
		if (searchValue != null) {
			if (Array.isArray(searchValue))
				searchValue.forEach((value) => {
					params.append(searchKey, value);
				});
			else params.append(searchKey, searchValue);
		}
	}
	return params;
}