import type { Commit, Dispatch } from 'vuex';
import { ActionTypes, MutationTypes, NAMESPACE, ProjectItem, SubmissionFileTypes } from '@/models/store/project.interface';
import { appUtilities } from '@/modules/appUtilities';
// mutations
export const mutationsWrapper = {
	updateProjectsList(
		{ commit }: { commit: Commit },
		payload: { currentPage: number; totalPages: number; projects: ProjectItem[]; pageSize: number }
	) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_PROJECTS_LIST}`, payload, { root: true });
	},
	resetProjectsList({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_PROJECTS_LIST}`, undefined, { root: true });
	},
	updateSharedProjectsList(
		{ commit }: { commit: Commit },
		payload: { currentPage: number; totalPages: number; projects: ProjectItem[]; pageSize: number }
	) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SHARED_PROJECTS_LIST}`, payload, { root: true });
	},
	resetSharedProjectsList({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_SHARED_PROJECTS_LIST}`, undefined, { root: true });
	},
	setLoadingStatus({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_LOADING_STATUS}`, isLoading, { root: true });
	},
	setIsLoadingShared({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_LOADING_SHARED}`, isLoading, { root: true });
	},
	updateProjectTitle({ commit }: { commit: Commit }, payload: { projectId: string; projectTitle: string }) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_PROJECT_TITLE}`, payload, { root: true });
	},
	setProjectsToDuplicate({ commit }: { commit: Commit }, payload: { projectToDuplicate: string; addToList: boolean }) {
		commit(`${NAMESPACE}/${MutationTypes.SET_PROJECT_TO_DUPLICATE}`, payload, { root: true });
	},
	toggleDeleteProject({ commit }: { commit: Commit }, payload: { isDelete: boolean; project: any; projectPose: number }) {
		commit(`${NAMESPACE}/${MutationTypes.TOGGLE_DELETE_PROJECT}`, payload, { root: true });
	},
	appendSharedProject({ commit }: { commit: Commit }, project: ProjectItem) {
		commit(`${NAMESPACE}/${MutationTypes.APPEND_SHARED_PROJECT_TO_LIST}`, project, { root: true });
	},
	removeSharedProject({ commit }: { commit: Commit }, project: ProjectItem) {
		commit(`${NAMESPACE}/${MutationTypes.REMOVE_SHARED_PROJECT_FROM_LIST}`, project, { root: true });
	},
};

export const actionsWrapper = {
	fetchProjectsOfUser(
		{ dispatch }: { dispatch: Dispatch },
		payload: { refresh: boolean; sortBy: string; type?: string; search: string | undefined; page: number; pageSize: number }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_PROJECTS_OF_USER}`, payload, { root: true });
	},

	fetchSharedProjects(
		{ dispatch }: { dispatch: Dispatch },
		payload: { refresh: boolean; sortBy: string; search: string | undefined; page: number; pageSize: number }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_SHARED_PROJECTS}`, payload, { root: true });
	},

	deleteProject({ dispatch }: { dispatch: Dispatch }, project: any, projectPose?: number) {
		return dispatch(`${NAMESPACE}/${ActionTypes.DELETE_PROJECT}`, { project, projectPose }, { root: true });
	},
	updateProjectTitle({ dispatch }: { dispatch: Dispatch }, payload: { projectId: string; projectTitle: string; originalTitle: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.UPDATE_PROJECT_TITLE}`, payload, { root: true });
	},

	createProject({ dispatch }: { dispatch: Dispatch }, payload: { inNewTab?: boolean; query?: any; projectTitle?: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.CREATE_PROJECT}`, payload, { root: true });
	},

	cloneProject({ dispatch }: { dispatch: Dispatch }, srcProjectId: string, openDuplicateTab = false) {
		return dispatch(`${NAMESPACE}/${ActionTypes.CLONE_PROJECT}`, { srcProjectId, openDuplicateTab }, { root: true });
	},
	duplicateProjectAsSubmission(
		{ dispatch }: { dispatch: Dispatch },
		payload: { projectId: string; submissionName: string; submissionType: SubmissionFileTypes }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.DUPLICATE_PROJECT_AS_SUBMISSION}`, payload, { root: true });
	},
	subscribeToSharedProject({ dispatch }: { dispatch: Dispatch }, userId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SUBSCRIBE_TO_SHARED_PROJECT}`, userId, { root: true });
	},
	// Function to create design project best to avoid logic duplication
	createDesignProject(context, router, query, ...mixpanelArgs: [string?, any?]) {
		if (mixpanelArgs?.length > 0) appUtilities.$mixpanel.track(...mixpanelArgs);
		const createDesignLink = router.resolve({
			name: 'DesignCreateMiddleware',
			query: { ...router.currentRoute.query, ...query },
		});
		const modalWindow = window.open(createDesignLink.href);
		if (!modalWindow || modalWindow?.closed) window.location.href = createDesignLink.href;
	},
};
