import { ProjectState } from '@/models/store/project.interface';

const MAX_PAGESIZE_LIST = 20;

export const getDefaultState = function (): ProjectState {
	return {
		projects: {
			listOfProjects: [],
			currentPage: 0,
			totalPages: 0,
			pageSize: MAX_PAGESIZE_LIST,
		},
		sharedProjects: {
			listOfProjects: [],
			currentPage: 0,
			totalPages: 0,
			pageSize: MAX_PAGESIZE_LIST,
		},
		searchQuery: '',
		isLoading: false,
		isLoadingShared: false,
		projectsToDuplicate: {},
	};
};

export default (): ProjectState => getDefaultState();
