import { getDefaultLibraryState, LIBRARY_FILES_PAGE_SIZE } from './state';
import { LIBRARY_TAB, GetterTypes, Getters } from '@/models/store/pixcapassets.interface';

const getters: Getters = {
	[GetterTypes.GET_LIBRARY_FILES_STATE]: (state) => (stateKey) => {
		const filesState = state.libraryFilesState[stateKey];
		const isLoading = state.isFetchingLibraryFiles[stateKey];
		if (filesState) return { ...filesState, isLoading };
		return { ...getDefaultLibraryState(LIBRARY_FILES_PAGE_SIZE), isLoading };
	},
	[GetterTypes.GET_LIBRARY_ITEM_COUNT_BASED_ON_ACTIVE_TAB]: (state) => {
		if (state.activeExplorerTab === LIBRARY_TAB.ALL) {
			if (!state.isFetchingTemplates && !state.isFetchingPacks && !state.isFetchingLibraryFiles.elements) {
				return state.libraryPacksState.totalItems + state.libraryTemplateState.totalItems + state.libraryFilesState.elements?.totalItems;
			}

			return undefined;
		}

		if (state.activeExplorerTab === LIBRARY_TAB.PACKS) {
			return !state.isFetchingPacks ? state.libraryPacksState.totalItems : undefined;
		}

		if (state.activeExplorerTab === LIBRARY_TAB.TEMPLATES) {
			return !state.isFetchingTemplates ? state.libraryTemplateState.totalItems : undefined;
		}

		const isLoading = state.isFetchingLibraryFiles[state.activeExplorerTab];
		const filesState = state.libraryFilesState[state.activeExplorerTab];

		if (isLoading) {
			return undefined;
		}
		return filesState ? filesState.totalItems : undefined;
	},
	[GetterTypes.GET_ALL_LIBRARY_ITEM_COUNT]: (state) => {
		if (!state.isFetchingTemplates && !state.isFetchingPacks && !state.isFetchingLibraryFiles.elements) {
			return state.libraryPacksState.totalItems + state.libraryTemplateState.totalItems + state.libraryFilesState.elements?.totalItems;
		}

		return undefined;
	},
};

export default getters;
