import type { ActionContext } from 'vuex';
import logger from '@pixcap/ui-core/helpers/logger';
import { HttpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import { CANCELLABLE_REQUEST_POOL } from '@pixcap/ui-core/services/httpclient/BaseHttpClient';
import { mutationsWrapper as AppMutations } from '@pixcap/ui-core/store/app';
import { NotificationType, SortBy } from '@pixcap/ui-core/models/store/app.interface';
import { RSocketCancelToken, WEBSOCKET_OPERATIONS, WebSocketManager } from '@pixcap/ui-core/services/socketmanager';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import { mutationsWrapper, actionsWrapper } from './wrapper';
import {
	ActionTypes,
	ProjectState,
	SubmissionFileTypes,
	INITIAL_EDITOR_STATE_KEY,
	SharedProjectActions,
	ProjectTypes,
} from '@/models/store/project.interface';

import { appUtilities } from '@/modules/appUtilities';
import { createRouter } from '@/router';

const router = createRouter();
let sharedProjectCancelToken: RSocketCancelToken;

const { ASSETMANAGER_PATH, AUTOSAVE_PATH } = API_PATHS;

export default {
	async [ActionTypes.FETCH_PROJECTS_OF_USER](
		context: ActionContext<any, any>,
		payload: { refresh: boolean; sortBy: string; type?: string; search: string | undefined; page: number; pageSize: number }
	) {
		const { refresh, sortBy, search, page, pageSize, type } = payload;
		if (refresh) mutationsWrapper.resetProjectsList(context);

		mutationsWrapper.setLoadingStatus(context, true);

		let isCancelled = false;
		try {
			const currentPage = page;
			const params: any = {
				page: currentPage,
				sortBy,
				search,
				pageSize,
			};
			if (type) params.animation = type == ProjectTypes.ANIMATED;
			const response = await (this.$httpClient as HttpClient).get(
				`${ASSETMANAGER_PATH}/project`,
				{
					params,
				},

				{ cancellableRequestPool: CANCELLABLE_REQUEST_POOL.FETCH_PROJECTS, isCancelPriors: true }
			);

			if (response == null) {
				isCancelled = true;
				return null;
			}

			if (response.data) {
				mutationsWrapper.updateProjectsList(context, {
					currentPage: response.data.page,
					totalPages: response.data.totalPages,
					projects: response.data.content,
					pageSize,
				});
			}
		} finally {
			if (!isCancelled) mutationsWrapper.setLoadingStatus(context, false);
		}
	},

	async [ActionTypes.FETCH_SHARED_PROJECTS](
		context: ActionContext<any, any>,
		payload: { refresh: boolean; sortBy: string; search: string | undefined; page: number; pageSize: number }
	) {
		const { refresh, sortBy, search, page, pageSize } = payload;
		if (refresh) mutationsWrapper.resetSharedProjectsList(context);
		mutationsWrapper.setIsLoadingShared(context, true);
		try {
			const currentPage = refresh ? 0 : page;
			const response = await (this.$httpClient as HttpClient).get(`${ASSETMANAGER_PATH}/project`, {
				params: {
					page: currentPage,
					sortBy,
					search,
					pageSize,
					shared: true,
				},
			});
			if (response.data) {
				mutationsWrapper.updateSharedProjectsList(context, {
					currentPage: response.data.content.length ? currentPage + 1 : currentPage,
					totalPages: response.data.totalPages,
					projects: response.data.content,
					pageSize,
				});
			}
		} finally {
			mutationsWrapper.setIsLoadingShared(context, false);
		}
	},

	async [ActionTypes.DELETE_PROJECT](context: ActionContext<any, any>, payload: { project: any; projectPose?: number }) {
		const { project, projectPose } = payload;
		const isFromProjectList = typeof project !== 'string';
		try {
			if (isFromProjectList) {
				mutationsWrapper.toggleDeleteProject(context, { isDelete: true, project, projectPose });
				const response = await (this.$httpClient as HttpClient).delete(`${ASSETMANAGER_PATH}/project/${project.projectId}`);
				if (response.data) appUtilities.$mixpanel.track('Delete Project');
				else mutationsWrapper.toggleDeleteProject(context, { isDelete: false, project, projectPose });
			} else {
				const response = await (this.$httpClient as HttpClient).delete(`${ASSETMANAGER_PATH}/project/${project}`);
				if (response.data) appUtilities.$mixpanel.track('Delete Project');
			}
		} catch {
			if (isFromProjectList) mutationsWrapper.toggleDeleteProject(context, { isDelete: false, project, projectPose });
		}
	},
	async [ActionTypes.UPDATE_PROJECT_TITLE](
		context: ActionContext<any, any>,
		payload: { projectId: string; projectTitle: string; originalTitle: string }
	) {
		const { projectId, projectTitle, originalTitle } = payload;
		try {
			const params: any = {
				projectId,
				projectTitle,
			};
			mutationsWrapper.updateProjectTitle(context, { projectId, projectTitle });
			const response = await (this.$httpClient as HttpClient).put(`${ASSETMANAGER_PATH}/project/${projectId}`, params);
			if (!response.data) mutationsWrapper.updateProjectTitle(context, { projectId, projectTitle: originalTitle });
		} catch {
			mutationsWrapper.updateProjectTitle(context, { projectId, projectTitle: originalTitle });
		}
	},
	async [ActionTypes.CREATE_PROJECT](context: ActionContext<any, any>, payload: { inNewTab?: boolean; query?: any; projectTitle?: string }) {
		const { inNewTab = false, query = {}, projectTitle } = payload;
		AppMutations.setLoadingStatus(context, {
			message: 'Creating project...',
		});
		try {
			const response = await (this.$httpClient as HttpClient).post(`${ASSETMANAGER_PATH}/project`, {
				projectTitle,
			});
			appUtilities.$mixpanel.track('Create Project');
			const project = response.data;
			if (project) {
				const projectInitialState = { isNewProject: true };
				const now = Date.now().toString();
				appUtilities.$services.localStorageManager.write(`${INITIAL_EDITOR_STATE_KEY}_${now}`, projectInitialState);
				const editorRoute = router.resolve({ name: 'Editor', params: { projectUUID: project.projectId }, query: { ...query, now } });
				if (inNewTab) {
					const newProjectWindow = window.open(editorRoute.href);
					// in case popup is not allowed
					if (newProjectWindow && !newProjectWindow.closed && typeof newProjectWindow.closed != 'undefined') {
						const projectsState: ProjectState = context.state;
						actionsWrapper.fetchProjectsOfUser(context, {
							page: 0,
							pageSize: projectsState.projects.pageSize,
							search: null,
							refresh: true,
							sortBy: SortBy.RECENT,
						});
						return;
					}
				}
				window.location.href = editorRoute.href;
			}
		} catch (e) {
			logger.error('failed to create project', e);
		} finally {
			AppMutations.setLoadingStatus(context, null);
		}
	},

	async [ActionTypes.CLONE_PROJECT](context: ActionContext<any, any>, payload: { srcProjectId: string; openDuplicateTab?: boolean }) {
		const { srcProjectId, openDuplicateTab = false } = payload;
		try {
			const newProjectId = uuidv4();
			let duplicateWindow: any;
			if (openDuplicateTab) {
				const duplicateRoute = router.resolve({ name: 'DuplicateProject', params: { projectUUID: newProjectId } });
				duplicateWindow = window.open(duplicateRoute.href);
			}
			const promise = new Promise<void>(async (resolve, reject) => {
				const webSocketManager = WebSocketManager.lastInitSocketManager;

				const data = { srcProjectId, newProjectId };
				const recoveryCallback = async () => {
					logger.log('Reconnecting to clone project response socket');
					try {
						const response = await (this.$httpClient as HttpClient).get(`${ASSETMANAGER_PATH}/project/${newProjectId}`);
						if (response && response.data) {
							resolve();
							return;
						} else {
							await webSocketManager.subscribeRequestResponse<{ srcProjectId: string; newProjectId: string }, void>(
								WEBSOCKET_OPERATIONS.SUBSCRIBE_CLONED_PROJECT,
								data,
								recoveryCallback
							);
							resolve();
						}
					} catch (err) {
						reject(err);
					}
				};
				try {
					await webSocketManager.subscribeRequestResponse<{ srcProjectId: string; newProjectId: string }, void>(
						WEBSOCKET_OPERATIONS.SUBSCRIBE_CLONED_PROJECT,
						data,
						recoveryCallback
					);
				} catch (reqErr) {
					reject(reqErr);
					return;
				}
				resolve();
			});
			await (this.$httpClient as HttpClient).put(`${AUTOSAVE_PATH}/project/${srcProjectId}/clone`, { newProjectId });
			await promise;

			if (duplicateWindow) {
				// use this in case loading duplicate page take time more than cloning project
				duplicateWindow.PIXCAP_DATA = {
					isCloned: true,
				};
				duplicateWindow.postMessage(newProjectId, '*');
			}
		} catch (e) {
			logger.warn('Encounter error when cloning project', e);
		}
	},
	async [ActionTypes.DUPLICATE_PROJECT_AS_SUBMISSION](
		context: ActionContext<any, any>,
		payload: { projectId: string; submissionName: string; submissionType: SubmissionFileTypes }
	) {
		try {
			const { projectId, submissionName, submissionType } = payload;
			const newProjectId = uuidv4();
			const response = await (this.$httpClient as HttpClient).put(`${AUTOSAVE_PATH}/project/${projectId}/clone`, {
				newProjectId,
				submission: {
					name: submissionName,
					itemType: submissionType,
				},
			});
			if (response.data)
				Vue.notify({
					type: NotificationType.SUCCESS,
					duration: 3000,
					title: 'Project successfully duplicated',
					text: "New submission created and it's now in Contributor file section",
				});
		} catch {
			Vue.notify({
				type: NotificationType.ERROR,
				title: 'Failed to create submission. Please try again.',
			});
		}
	},
	async [ActionTypes.SUBSCRIBE_TO_SHARED_PROJECT](context: ActionContext<any, any>, userId: string) {
		try {
			const webSocketManager = WebSocketManager.lastInitSocketManager;
			const data = { userId };

			const onReceiveCallback = (payload: { project: string; action: SharedProjectActions }) => {
				const { project: projectString, action } = payload;
				const project = JSON.parse(projectString);
				switch (action) {
					case SharedProjectActions.ADDED:
						mutationsWrapper.appendSharedProject(context, project);
						break;
					case SharedProjectActions.REMOVED:
						mutationsWrapper.removeSharedProject(context, project);
						break;
					default:
						break;
				}
			};

			const recoveryCallback = async () => {
				logger.log('Reconnecting to shared project streem socket');
				await webSocketManager.subscribeRequestStream(WEBSOCKET_OPERATIONS.SUBSCRIBE_SHARED_PROJECTS, data, onReceiveCallback, recoveryCallback);
			};

			if (sharedProjectCancelToken) {
				sharedProjectCancelToken.cancel();
				sharedProjectCancelToken = new RSocketCancelToken();
			} else {
				sharedProjectCancelToken = new RSocketCancelToken();
			}

			await webSocketManager.subscribeRequestStream(
				WEBSOCKET_OPERATIONS.SUBSCRIBE_SHARED_PROJECTS,
				data,
				onReceiveCallback,
				recoveryCallback,
				null,
				sharedProjectCancelToken
			);
		} catch (e) {
			logger.warn('Subscribe to shared project streem fails', e);
		}
	},
};
