import type { ActionContext } from 'vuex';
import { HttpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import { ActionTypes } from '@/models/store/organism.interface';
import { mutationsWrapper } from '@/store/organism/wrapper';
import logger from '@pixcap/ui-core/helpers/logger';

const ASSETMANAGER_PATH = API_PATHS.ASSETMANAGER_PATH;
export default {
	async [ActionTypes.GET_CATEGORY_FOOTER_COUNT](context: ActionContext<any, any>) {
		mutationsWrapper.setCategoryFooterCount(context, null);
		const fintechTag = this.app.i18n.t('tag_name.fintech');
		const ecommerceTag = this.app.i18n.t('tag_name.ecommerce');
		const healthcareTag = this.app.i18n.t('tag_name.healthcare');
		const businessTag = this.app.i18n.t('tag_name.business');
		const casualTag = this.app.i18n.t('tag_name.casual');
		const professionTag = this.app.i18n.t('tag_name.profession');
		const laptopTag = this.app.i18n.t('tag_name.laptop');
		const smartphoneTag = this.app.i18n.t('tag_name.smartphone');
		const brandingTag = this.app.i18n.t('tag_name.branding');

		const illustrationResponse = (this.$httpClient as HttpClient).get(
			`${ASSETMANAGER_PATH}/library/packs/count?tagName=${fintechTag}&tagName=${ecommerceTag}&tagName=${healthcareTag}&resourceType=scenes`
		);
		const charactersResponse = (this.$httpClient as HttpClient).get(
			`${ASSETMANAGER_PATH}/library/packs/count?tagName=${businessTag}&tagName=${casualTag}&tagName=${professionTag}&resourceType=characters`
		);
		const mockupResponse = (this.$httpClient as HttpClient).get(
			`${ASSETMANAGER_PATH}/library/pixcap/count?tagName=${laptopTag}&tagName=${smartphoneTag}&tagName=${brandingTag}&itemType=ANIMATED_MOCKUP_TEMPLATES&itemType=STATIC_MOCKUP_TEMPLATES`
		);
		const promises = [illustrationResponse, charactersResponse, mockupResponse];
		const responses = await Promise.all(promises).catch((err) => logger.error(err, 'Encounter error fetching category count'));
		mutationsWrapper.setCategoryFooterCount(context, {
			illustration: responses[0].data,
			characters: responses[1].data,
			mockups: responses[2].data,
		});
	},

	async [ActionTypes.GET_FOOTER_SEO](context: ActionContext<any, any>, payload: { type: string }) {
		try {
			const { type } = payload;
			const response = await (this.$httpClient as HttpClient).get(`${ASSETMANAGER_PATH}/library/pixcap/explore-searches`, {
				params: {
					page: 0,
					pageSize: 30,
					type,
				},
			});
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			logger.log('Error while getting footer seo', e);
		}
	},
};
