import { MutationTypes, ProjectState } from '@/models/store/project.interface';

export default {
	[MutationTypes.UPDATE_PROJECTS_LIST](state, payload) {
		const { currentPage, totalPages, projects, pageSize } = payload;
		state.projects = {
			pageSize,
			currentPage,
			totalPages,
			listOfProjects: [...state.projects.listOfProjects, ...projects],
		};
	},
	[MutationTypes.RESET_PROJECTS_LIST](state) {
		state.projects = {
			...state.projects,
			listOfProjects: [],
			currentPage: 0,
			totalPages: 0,
		};
	},
	[MutationTypes.UPDATE_SHARED_PROJECTS_LIST](state, payload) {
		const { currentPage, totalPages, projects, pageSize } = payload;
		state.sharedProjects = {
			pageSize,
			currentPage,
			totalPages,
			listOfProjects: [...state.sharedProjects.listOfProjects, ...projects],
		};
	},
	[MutationTypes.RESET_SHARED_PROJECTS_LIST](state) {
		state.sharedProjects = {
			...state.sharedProjects,
			listOfProjects: [],
			currentPage: 0,
			totalPages: 0,
		};
	},
	[MutationTypes.SET_IS_LOADING_SHARED](state, isLoadingShared) {
		state.isLoadingShared = isLoadingShared;
	},
	[MutationTypes.TOGGLE_DELETE_PROJECT](state: ProjectState, payload) {
		const { isDelete, project, projectPose } = payload;
		if (isDelete) {
			state.projects.listOfProjects = state.projects.listOfProjects.filter((_) => _.projectId != project.projectId);
		} else {
			const listOfProjects = [...state.projects.listOfProjects];
			listOfProjects.splice(projectPose, 0, project);
			state.projects.listOfProjects = listOfProjects;
		}
	},
	[MutationTypes.SET_LOADING_STATUS](state, isLoading) {
		state.isLoading = isLoading;
	},
	[MutationTypes.UPDATE_PROJECT_TITLE](state, { projectId, projectTitle }) {
		const project = state.projects.listOfProjects.find((project) => project.projectId === projectId);
		if (project) project.projectTitle = projectTitle;
	},
	[MutationTypes.SET_PROJECT_TO_DUPLICATE](state, payload) {
		const { projectToDuplicate, addToList } = payload;
		const projectsToDuplicate = { ...state.projectsToDuplicate };
		if (addToList) projectsToDuplicate[projectToDuplicate] = true;
		else delete projectsToDuplicate[projectToDuplicate];
		state.projectsToDuplicate = projectsToDuplicate;
	},
	[MutationTypes.APPEND_SHARED_PROJECT_TO_LIST](state, project) {
		const listOfProjects: any[] = state.sharedProjects.listOfProjects;
		if (listOfProjects.findIndex((item) => item.projectId == project.projectId) >= 0) return;
		state.sharedProjects.listOfProjects = [project, ...state.sharedProjects.listOfProjects];
	},
	[MutationTypes.REMOVE_SHARED_PROJECT_FROM_LIST](state, project) {
		const listOfProjects = state.sharedProjects.listOfProjects;
		state.sharedProjects.listOfProjects = listOfProjects.filter((item) => item.projectId != project.projectId);
	},
};
