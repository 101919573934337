export function transformResourceType(resourceType: string, reverse = false) {
	if (!resourceType) return '';
	resourceType = resourceType.toLowerCase();
	const lastChar = resourceType.length - 1;
	if (reverse) {
		if (resourceType[lastChar] != 's') return resourceType + 's';
	} else if (resourceType[lastChar] == 's') return resourceType.slice(0, -1);
	return resourceType;
}

export function transformSearchWord(search: string, reverse = false) {
	if (reverse) return search.replaceAll('-', ' ');
	return search.replaceAll(' ', '-');
}

export function capitaliseString(str: string) {
	if (!str) return '';
	const lowerCased = str.toLowerCase();
	return lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
}

export function highlightTextDirective(el, binding, highlightClassName = 'highlight-search-suggestion') {
	const { value: { suggestion, search } = { search: null, suggestion: null } } = binding;
	if (search) {
		const iQuery = new RegExp(regExpQuote(search), 'ig');
		const innerHTML = suggestion.toString().replace(iQuery, function (matchedTxt) {
			return `<span class="${highlightClassName}">${matchedTxt}</span>`;
		});
		el.innerHTML = innerHTML;
	} else {
		el.innerHTML = suggestion;
	}
}

export function regExpQuote(str) {
	return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
}
